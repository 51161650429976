import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FontStyles from "../../theme/font";
import Color from "../../theme/color";
import checkImage from "../../aasets/images/regularcheck-circle (1).png";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import ButtonUI from "../../components/buttonUi";
import { Parser } from "html-to-react";

import { useLocation, useNavigate } from "react-router-dom";

import { GET, POST } from "../../config/https";
import APIS from "../../config/api";
import FullScreenLoadder from "../../components/fullScreenLoadder";
import DaysModal from "../../components/daysModal";
import getUserData from "../../utils/getLocalStorageData";
import { usePersistentState } from "../../contexts/GlobalContext";

import SubcriptionModal from "../../components/subscriptionModal";

import PaymentCard from "src/components/payment/home";
import CheckSubceiption from "src/utils/checkSubscription";
import useScreenOrientation from "./useScreenOrientation";
import likeHeart from "../../aasets/images/heartCard.png";
import FavoriteModal from "../../components/favoriteModal";
import { toast } from "react-hot-toast";

const RecipeDetailsPage = () => {
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [categoryItem, setCategoryItem] = useState(Category);
  const [openFavouriteModal, setOpenFavouriteModal] = useState(false);
  const handleCloseFavourite = () => {
    setOpenFavouriteModal(false);
    setRecipeDetailPageid("");
  };
  const [daysModal, setDaysModal] = useState(false);
  const [recipeId, setRecipeId] = useState("");
  const [recipe, setRecipe] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [subcriptionModal, setSubcriptiomModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentStatus, setPaymentStuts] = useState(undefined);
  const [isPortrait, setIsPortrait] = useState(true);

  const [, dispatch]: any = usePersistentState();
  const navigate = useNavigate();
  const location: any = useLocation();
  const contentSpacing = "20px";
  const blockSpacing = "25px";
  const theme = useTheme();
  const lableStyle = { ...FontStyles.font16Prociono };
  const valueStyle = { ...FontStyles.font24Prociono };

  const handleFavourite = (id?: string, titleRecipe?: string) => {
    setRecipeName(titleRecipe || "");
    setRecipeDetailPageid(id || "");
    setOpenFavouriteModal(true);
  };

  const [recipeDetailPageId, setRecipeDetailPageid] = useState("");
  const [recipeName, setRecipeName] = useState("");

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));

  const orientation: OrientationType = useScreenOrientation()

  useEffect(() => {
    if (orientation === 'portrait-primary') {
      setIsPortrait(true)
    } else {
      setIsPortrait(false)
    }
  }, [orientation])

  const setDay = (id: number) => {
    let data = categoryItem.map((item) => {
      if (item.id === id) {
        return { ...item, check: !item.check };
      }
      return item;
    });
    setCategoryItem(data);
  };

  useEffect(() => {
    let temp = location?.state;
    setSubcriptionStuts();
    dispatch({ isSearch: false });
    try {
      if (temp.recipePage) {
        setRecipeId(temp?.temp);
        getIdByRecipe();
      }
    } catch {
      checkPage();
    }
  }, []);

  const checkPage = async () => {
    await navigate("/dashboard/app");
  };
  const getIdByRecipe = async () => {
    let userData = getUserData();
    if (!userData) return;
    setIsLoading(true);
    try {
      let params = {};
      await GET(
        `${APIS.GET_RECIPE_BY_ID}?id=${recipeId ? recipeId : location?.state.recipeId
        }`,
        params,
        userData?.jwtToken
      )
        .then((res: any) => {
          if (res.isSuccess) {
            setRecipe(res.result);
          }
        })
        .catch((error) => { });
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const addFavoriteRecipe = async () => {
    let userData = getUserData();
    if (!userData) return;
    setIsAddLoading(true);
    let params = {
      id: recipeDetailPageId,
      isFavorite: true,
    };

    await POST(APIS.ADD_FAVORITE_RECIPE, params, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          toast.success("Recipe successfully add in favorite");
          setOpenFavouriteModal(false);
        }
        if (!res.isSuccess && res.errors) {
          toast.error(res.errors[0]);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setOpenFavouriteModal(false);
        setRecipeDetailPageid("");
        setIsAddLoading(false);
      });
  };

  const addRecipeDay = () => {
    // let data = addDateDays(14);
    let temp = location?.state.recipeId;
    setRecipeId(temp);
    if (!paymentStatus) {
      setDaysModal(true);
    }
    if (paymentStatus) {
      setSubcriptiomModal(true);
    }
  };

  const handaleSubcription = () => {
    // setSubcriptiomModal(false);
    // setPaymentModal(true);
    navigate("/dashboard/subscriptionplan");
  };

  const setSubcriptionStuts = async () => {
    let temp = await CheckSubceiption();
    setPaymentStuts(temp?.isSubscriptionRequired);
  };

  const renderOtherAttributes = () => {
    if (
      recipe.quickPreparation ||
      recipe.seasonal ||
      recipe.spicy ||
      recipe.featured
    ) {
      return (
        <Typography
          style={{
            ...FontStyles.font24Prociono,
            color: Color.color4,
            marginTop: blockSpacing,
            marginBottom: contentSpacing,
          }}
        >
          Other Attributes
        </Typography>
      );
    }
  };

  return (
    <>
      <Container
        component="main"
        sx={{
          marginTop: "50px",
          width: "100%",
          color: Color.color10,
        }}
      >

        {/* <div>
          <h2>Width: {windowSize.current[0]}</h2>
          <h2>Height: {windowSize.current[1]}</h2>
        </div> */}

        {/* <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <Grid item xs={12} sm={12} md={6}>
              sdsdsd
            </Grid>
            {!isMatch ? (
              <Grid item xs={0} sm={0} md={6}>
                sdsdsd
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            ddd
          </Grid>
        </Grid> */}
        {recipe.hasOwnProperty("name") ? (
          // <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 5 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display={"inline-flex"}
              justifyContent={"center"}
              paddingRight={isMatch ? 10 : undefined}
              paddingLeft={isMatch ? 10 : undefined}
            > */}
            <Grid item xs={12} sm={12} md={6}>
              {/* <img
                src={recipe.imagePath}
                alt="recipeimage"
                style={{
                  height: isMatch ? "200px" : "323px",
                  width: isMatch ? "350px" : "431px",
                }}
              /> */}
              <Grid item xs={0} sm={0} md={12} sx={{ alignItems: 'center', justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
                <img
                  src={recipe.imagePath}
                  alt="recipeimage"
                  style={{
                    // height: isMatch ? "200px" : "323px",
                    // width: isMatch ? "350px" : "431px",
                    height: Number(windowSize.current[0]) < 480 && isPortrait ? "200px" : "300px",
                    width: Number(windowSize.current[0]) < 480 && isPortrait ? "100%" : "565px",
                  }}
                />
              </Grid>
              {!isMatch ? (
                <>
                  <Grid item xs={0} sm={0} md={12}>
                    {recipe?.dietAttributes &&
                      Object.values(recipe?.dietAttributes).some(
                        (item) => item
                      ) ? (
                      <>
                        <Typography
                          style={{
                            ...FontStyles.font24Prociono,

                            color: Color.color4,
                            marginTop: blockSpacing,
                            marginBottom: contentSpacing,
                          }}
                        >
                          Diet Attributes
                        </Typography>
                        <Grid
                          container
                          spacing={{ xs: 1, md: 1, sm: 1 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          {recipe.dietAttributes ? (
                            <DietAttributeComponent
                              data={recipe.dietAttributes}
                              categoryItem={categoryItem}
                              setIcon={(id: any) => setDay(id)}
                            />
                          ) : null}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                  <Grid item xs={0} sm={0} md={12}>
                    {recipe.about && (
                      <>
                        <Typography
                          style={{
                            ...FontStyles.font24Prociono,

                            color: Color.color4,
                            marginTop: blockSpacing,
                          }}
                        >
                          Description
                        </Typography>

                        <Typography
                          style={{
                            // ...FontStyles.font24Prociono,
                            // lineHeight: "24px",
                            // color: Color.color5,
                            marginTop: contentSpacing,
                          }}
                        >
                          {" "}
                          {Parser().parse(
                            recipe.about ? recipe.about : "<p></p>"
                          )}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </>
              ) : null}
            </Grid>

            {/* <Grid item xs={12} sm={12} md={6}> */}
            <Grid item xs={12} sm={12} md={6}>
            <IconButton
                  size="small"
                  style={{ paddingRight: 1, padding: 0 }}
                  onClick={() => handleFavourite && handleFavourite(recipe.id, recipe.name)}
                >
                  <img
                    alt="heart"
                    src={likeHeart}
                    style={{ width: "16px", height: "20px" }}
                  />
              </IconButton>
              <Typography
                style={{
                  ...FontStyles.font24Prociono,

                  color: Color.color4,
                  marginTop: isMatch ? 3 : undefined,

                  wordWrap: "break-word",
                }}
              >
                {recipe?.name}
              </Typography>
              {recipe.cookTime && (
                <Typography
                  style={{
                    ...FontStyles.font16Prociono,

                    color: Color.color5,
                    marginTop: contentSpacing,
                    wordWrap: "break-word",
                  }}
                >
                  {recipe.cookTime}
                </Typography>
              )}
              {isMatch &&
                recipe?.dietAttributes &&
                Object.values(recipe?.dietAttributes).some((item) => item) ? (
                <>
                  <Typography
                    style={{
                      ...FontStyles.font24Prociono,
                      color: Color.color4,
                      marginTop: blockSpacing,
                      marginBottom: contentSpacing,
                    }}
                  >
                    Diet Attributes
                  </Typography>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1, sm: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {recipe.dietAttributes ? (
                      <DietAttributeComponent
                        data={recipe.dietAttributes}
                        categoryItem={categoryItem}
                        setIcon={(id: any) => setDay(id)}
                      />
                    ) : null}
                  </Grid>
                </>
              ) : null}
              {isMatch && recipe.about && (
                <>
                  <Typography
                    style={{
                      ...FontStyles.font24Prociono,

                      color: Color.color4,
                      marginTop: blockSpacing,
                    }}
                  >
                    Description
                  </Typography>

                  <Typography
                    style={{
                      // ...FontStyles.font12Prociono,
                      // lineHeight: "12px",
                      // color: Color.color5,
                      marginTop: contentSpacing,
                    }}
                  >
                    {Parser().parse(recipe.about ? recipe.about : "<p></p> ")}
                  </Typography>
                </>
              )}

              <>
                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    color: Color.color4,
                    marginTop: blockSpacing,
                    marginBottom: contentSpacing,
                  }}
                >
                  Ingredients
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1, sm: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {recipe.ingredients.map((x: any, index: number) => {
                    return (
                      // <Grid item xs={4} sm={4} md={6} key={index}>
                      //   <Box
                      //     sx={{
                      //       flexGrow: 1,
                      //     }}
                      //     display="inline-flex"
                      //     alignSelf={"center"}
                      //     alignItems={"center"}
                      //     justifyContent={"left"}
                      //   >
                      //     <Box
                      //       display="inline-flex"
                      //       flexDirection={"row"}
                      //       justifyContent={"center"}
                      //       alignItems={"center"}
                      //       alignSelf={"center"}
                      //       textAlign={"center"}
                      //     >
                      //       <IconButton
                      //         onClick={() => setDay(x.id)}
                      //         sx={{ padding: 0 }}
                      //       >
                      // <img
                      //   alt="check"
                      //   src={checkImage}
                      //   style={{ width: "24px", height: "24px" }}
                      // />
                      //       </IconButton>
                      // <Typography
                      //   style={{
                      //     ...FontStyles.font20Prociono,
                      //     lineHeight: "12px",
                      //     color: Color.color4,
                      //     paddingLeft: 5,
                      //   }}
                      //   textAlign={"left"}
                      // >
                      //   {`${x.quantity} ${x.unit} - ${x.name}`}
                      // </Typography>
                      //     </Box>
                      //   </Box>
                      // </Grid>
                      <Grid container item sm={6} md={12 / 2} xs={6}>
                        <Grid xs={1}>
                          <Typography
                            alignItems={"center"}
                            display={"flex"}
                            justifyContent={"center"}

                          // sx={{ marginTop: "6px" }}
                          >
                            <img
                              alt="check"
                              src={checkImage}
                              style={{
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid xs={11}>
                          <Grid xs={12}>
                            <Typography
                              sx={{
                                ...FontStyles.font16Prociono,
                                color: Color.color5,
                                paddingLeft: isMatch ? 1.2 : 0.5,
                              }}
                            >
                              {`${Number(x.quantity)} ${x.unit} - ${x.name}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
              {renderOtherAttributes()}

              <Grid
                container
                spacing={{ xs: 1, md: 1, sm: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {recipe.quickPreparation && (
                  <OtherAttributeComponent
                    data={recipe.quickPreparation}
                    lable={"Quick Preparation"}
                  />
                )}
                {recipe.seasonal && (
                  <OtherAttributeComponent
                    data={recipe.seasonal}
                    lable={"Seasonal"}
                  />
                )}
                {recipe.spicy && (
                  <OtherAttributeComponent
                    data={recipe.spicy}
                    lable={"Spicy"}
                  />
                )}
                {recipe.featured && (
                  <OtherAttributeComponent
                    data={recipe.featured}
                    lable={"Featured"}
                  />
                )}
              </Grid>
              {recipe.stepToMake && (
                <>
                  <Typography
                    style={{
                      ...FontStyles.font24Prociono,
                      color: Color.color4,
                      marginTop: blockSpacing,
                    }}
                  >
                    How to Make
                  </Typography>
                  <Typography
                    style={
                      {
                        // ...FontStyles.font24Prociono,
                        // fontSize: "24px",
                        // lineHeight: "24px",
                        // color: Color.color5,
                      }
                    }
                  >
                    {Parser().parse(
                      recipe.stepToMake ? recipe.stepToMake : "<p></p>"
                    )}
                  </Typography>
                </>
              )}
              <Typography
                style={{
                  marginTop: "40px",
                  width: "100%",
                  justifyContent: "space-between",
                }}
                display={"inline-flex"}
                justifyContent={"space-between"}
              >
                <ButtonUI
                  label="Add Recipe"
                  variant="outlined"
                  action={() => addRecipeDay()}
                  style={{
                    backgroundColor: Color.color1,
                    borderColor: Color.color1,
                    width: "100%",
                  }}
                  lableStyle={{
                    ...FontStyles.font14Prociono,
                    color: Color.white,
                    fontWeight: "400",
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Box></Box>
        <DaysModal
          open={daysModal}
          data={recipeId}
          onClose={() => setDaysModal(false)}
          pageTitle="recipedetail"
        />
         <FavoriteModal
          open={openFavouriteModal}
          onClickAddFavorite={() => addFavoriteRecipe()}
          onClickRemove={() => {
            handleCloseFavourite();
            setRecipeDetailPageid("");
          }}
          onCloseHandle={() => handleCloseFavourite()}
          titleRecipeName={recipeName}
          isLoading={isAddLoading}
        />
        <SubcriptionModal
          open={subcriptionModal}
          onCloseHandle={() => setSubcriptiomModal(false)}
          upgradeNowAction={handaleSubcription}
        />
        <PaymentCard
          open={paymentModal}
          handleClose={() => {
            setPaymentModal(false);
            setSubcriptionStuts();
          }}
        />
      </Container>
      <FullScreenLoadder open={isLoading} />
    </>
  );
};
export default RecipeDetailsPage;
const Category = [
  {
    id: 1,
    name: "Vegetarian",
    check: false,
  },
  {
    id: 2,
    name: "Nut-Free",
    check: false,
  },
  {
    id: 3,
    name: "Soy-Free",
    check: false,
  },
  {
    id: 4,
    name: "Egg-Free",
    check: false,
  },
];

const DietAttributeComponent: React.FC<MyComponentProps> = (props) => {
  const { data } = props;
  const temp = Object.entries(data);
  const filteredArr = temp.filter(function ([key, value]) {
    return value !== false;
  });
  const finalData = Object.fromEntries(filteredArr);
  const dietAttributesLable = (key: any) => {
    let data = DietAttributesList.find((x) => x.lable === key);
    return data?.key;
  };
  return (
    <>
      {finalData
        ? Object.entries(finalData).map(([key, value], index) => {
          return (
            <Grid item xs={2} sm={2} md={4} key={index}>
              <Box
                sx={{
                  flexGrow: 1,
                }}
                display="inline-flex"
                alignSelf={"center"}
                alignItems={"center"}
                justifyContent={"left"}
              >
                <IconButton
                  // onClick={() => setIcon(x.id)}
                  sx={{ padding: 0 }}
                >
                  {value ? (
                    <img
                      alt="check"
                      src={checkImage}
                      style={{ width: "24px", height: "24px" }}
                    />
                  ) : (
                    <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
                <Typography
                  style={{
                    ...FontStyles.font16Prociono,
                    color: Color.color5,
                    paddingLeft: 5,
                  }}
                >
                  {dietAttributesLable(key)}
                </Typography>
              </Box>
            </Grid>
          );
        })
        : null}
    </>
  );
};

const OtherAttributeComponent = ({ data, lable }: any) => {
  return (
    <Grid item xs={2} sm={2} md={4}>
      <Box
        sx={{
          flexGrow: 1,
        }}
        display="inline-flex"
        alignSelf={"center"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        <IconButton
          // onClick={() => setIcon(x.id)}
          sx={{ padding: 0 }}
        >
          {data ? (
            <img
              alt="check"
              src={checkImage}
              style={{ width: "24px", height: "24px" }}
            />
          ) : (
            <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
          )}
        </IconButton>
        <Typography
          style={{
            ...FontStyles.font16Prociono,
            color: Color.color5,
            paddingLeft: 5,
          }}
        >
          {lable}
        </Typography>
      </Box>
    </Grid>
  );
};

interface MyComponentProps {
  data: any;
  categoryItem?: any;
  setIcon?: (id: string) => void;
}

const DietAttributesList = [
  {
    lable: "vegetarian",
    key: "Vegetarian",
  },
  {
    lable: "nutFree",
    key: "Nut-Free",
  },
  {
    lable: "dairyFree",
    key: "Dairy-Free",
  },
  {
    lable: "soyFree",
    key: "Soy-Free",
  },
  {
    lable: "eggFree",
    key: "Egg-Free",
  },
  {
    lable: "wheatFree",
    key: "Wheat-Fee",
  },
  {
    lable: "sesameFree",
    key: "Sesame-free",
  },
];

// const otherAttributeList = [
//   {
//     lable: "spicy",
//     key: "Spicy",
//   },
//   {
//     lable: "seasonal",
//     key: "Seasonal",
//   },
//   {
//     lable: "quickPreparation",
//     key: "QuickPreparation",
//   },
//   {
//     lable: "featured",
//     key: "Featured",
//   },
// ];
